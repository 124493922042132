export default function StrateSaleSteps() {
  return (
    <>
      <section class="plot-strate sale-steps">
        <div class="content-part">
          <h2>Toutes les étapes pour vendre son bien à Cogedim</h2>
          <div class="items">
            <div class="item">
              <span class="number">1</span>
              <h3>Votre demande en ligne</h3>
              <p>Votre proposition est étudiée par les équipes de Cogedim.</p>
            </div>
            <div class="item">
              <span class="number">2</span>
              <h3>Première estimation de votre bien</h3>
              <p>
                Nous vous contactons pour vous donner une première estimation de
                la valeur de rachat.
              </p>
            </div>
            <div class="item">
              <span class="number">3</span>
              <h3>Étude de faisabilité</h3>
              <p>
                Grâce à une étude financière détaillée, nous vous proposons un
                prix d'achat optimisé selon la localisation et le nombre de
                logements réalisables.
              </p>
            </div>
            <div class="item">
              <span class="number">4</span>
              <h3>La signature de la promesse de vente</h3>
              <p>
                Devant un notaire aux conditions de prix négociées avec Cogedim
                jusqu'à l'obtention des autorisations administratives.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
