export default function StratePlotCogedim() {
  return (
    <>
      <section class="plot-strate plot-cogedim">
        <div class="content-part">
          <h2>Pourquoi faire appel à Cogedim&nbsp;?</h2>
          <div class="items">
            <div class="item">
              <h3>Consulter des experts</h3>
              <p>
                Pour savoir si votre terrain est constructible, faites appel à
                nos spécialistes (PLU, projets de construction et d'aménagements
                possibles dans votre région).
              </p>
            </div>
            <div class="item">
              <h3>Estimer et valoriser votre terrain</h3>
              <p>
                Grâce à une étude de faisabilité qui visera à optimiser le
                potentiel de votre terrain pour vous en offrir le meilleur prix.
              </p>
            </div>
            <div class="item">
              <h3>Zéro frais</h3>
              <p>
                Réalisez des économies de frais d'agences en vous adressant
                directement à nos équipes.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
