import type { FormProps } from "~/types/form";
import type {
  PlotFormInput,
  PlotFormOutput,
} from "~/components/Forms/PlotSchema";

import type {
  SubmissionFailed,
  SubmissionSuccessful,
} from "~/components/Forms/api";
import { dispatch, isSubmittedInError } from "~/components/Forms/api";
import {
  hasBeenSubmitted,
  isSubmittedButNotValid,
  isSubmittedAndSuccessful,
} from "~/components/Forms/api";
import {
  createEffect,
  createSignal,
  DEV,
  onCleanup,
  onMount,
  Show,
} from "solid-js";
import { useSubmission } from "@solidjs/router";

import { useFormContext } from "~/contexts/FormContext";

import { SharedHiddenFields } from "./Fields/SharedHiddenFields";
import { FirstName } from "~/components/Forms/Fields/FirstName";
import { LastName } from "./Fields/LastName";
import { Email } from "./Fields/Email";
import { Phone } from "./Fields/Phone";
import { Civility } from "./Fields/Civility";
import { Location } from "./Fields/Location";

import { DebugDumper } from "./Utils/DebugDumper";
import { useFormStorageContext } from "~/contexts/FormStorageProvider";
import { useEventsContext } from "~/contexts/EventsContext";

import IconAccountBox from "~/img/icons/account_box.svg";
import type { FormSystemName } from "~/types/form_system_name";
import { OptInCogedim } from "./Fields/OptInCogedim";
import { Legals } from "./Utils/Legals";
import { Errors } from "./Utils/Errors";
import toast from "solid-toast";
import { useCapencyContext } from "~/contexts/CapencyContext";
import { isCapencyResponse } from "./api";
import CapencyReporter from "./Utils/CapencyReporter";
import { PlotLocation } from "./Fields/PlotLocation";
import { PlotPostalCode } from "./Fields/PlotPostalCode";
import { PlotSurface } from "./Fields/PlotSurface";
import { PlotComments } from "./Fields/PlotComments";

const debug = import.meta.env.VITE_COG_DEBUG_FORMS == "1";

const FORM_NAME: FormSystemName = "plot";

type Success = SubmissionSuccessful<PlotFormOutput>;

export default function Plot(props: FormProps<PlotFormInput>) {
  const [, { sendSubmitEvent }] = useEventsContext();
  const [formStore, { setValue }] = useFormContext();
  const [, { getFormValuesFromStorage, setFormValuesToStorage }] =
    useFormStorageContext();
  const [
    capencyCtx,
    {
      setCapencyLoading,
      setBypassingCapency,
      setCapencyChallengeSucceeded,
      setCapencyShowFailedPanel,
      setEmailMessage,
      setPhoneMessage,
    },
  ] = useCapencyContext();

  const capencyIsDisplayed = () => {
    return (
      capencyCtx.isEnabled &&
      !capencyCtx.isBypassingCapency &&
      (capencyCtx.showFailedPanel || capencyCtx.isLoading)
    );
  };

  const performCapencyCheck = () => {
    const letgo =
      capencyCtx.isCapencyChallengeSucceeded || capencyCtx.isBypassingCapency;
    return capencyCtx.isEnabled && !letgo ? "1" : "0";
  };

  createEffect(() => {
    const v = {
      ...getFormValuesFromStorage(),
      ...props.defaultValues,
      ...props.hiddenFields,
    };
    for (const [key, value] of Object.entries(v || {})) {
      setValue!(key, value as string);
    }
  });

  let btn;

  const submission = useSubmission(dispatch);

  const [start, setStart] = createSignal(false);

  setBypassingCapency!(false);
  setCapencyShowFailedPanel!(false);
  setCapencyChallengeSucceeded!(false);

  createEffect(() => {
    if (start()) {
      // Capency validation went smoothly proceed to submission
      if (isCapencyResponse(submission.result) && submission.result?.valid) {
        setCapencyChallengeSucceeded!(true);
        btn!.click();
      }

      // Capency validation failed
      if (
        isCapencyResponse(submission.result) &&
        submission.result?.valid === false
      ) {
        setCapencyShowFailedPanel!(true);
        setCapencyChallengeSucceeded!(false);
        const ret = submission.result;
        // To avoid the loop effect after re-opening the form upon successful submission
        setEmailMessage!(ret?.data?.email?.sMessage);
        setPhoneMessage!(ret?.data?.phone?.MessageCP?.sMessage);
      }

      // Successful form submission
      if (!isCapencyResponse(submission.result) && submission.result?.success) {
        setCapencyShowFailedPanel!(false);
        sendSubmitEvent({
          crmValues: submission.result.crm.data,
          formValues: formStore.values,
        });
        setFormValuesToStorage((submission.result as Success)?.output);
        setStart(false);
      }

      // Submission failed
      if (
        !isCapencyResponse(submission.result) &&
        isSubmittedInError(submission)
      ) {
        setCapencyShowFailedPanel!(false);
        console.error((submission.result as SubmissionFailed).issues);
        toast.error("Une erreur est survenue. Merci de réessayer plus tard.", {
          duration: 5000,
        });
      }
    }
  });

  // Auto-submit the form if the user is bypassing Capency
  createEffect(() => {
    if (capencyCtx.isBypassingCapency) {
      DEV && console.log("Bypassing Capency");
      btn!.click();
    }
  });

  // Show the Capency loading panel
  createEffect(() => {
    setCapencyLoading!(
      Boolean(submission.pending) && !capencyCtx.isBypassingCapency,
    );
  });

  // Housekeeping
  onMount(() => {
    if (hasBeenSubmitted(submission)) {
      submission.clear();
      setBypassingCapency!(false);
      setCapencyShowFailedPanel!(false);
      setCapencyChallengeSucceeded!(false);
    }
  });

  // Housekeeping
  onCleanup(() => {
    if (hasBeenSubmitted(submission)) {
      submission.clear();
      setBypassingCapency!(false);
      setCapencyShowFailedPanel!(false);
      setCapencyChallengeSucceeded!(false);
    }
  });

  return (
    <>
      <Show when={start() && capencyIsDisplayed()}>
        <CapencyReporter
          isLoading={capencyCtx.isLoading}
          showFailedPanel={capencyCtx.showFailedPanel}
          email={formStore.values.email}
          phone={formStore.values.phone}
          emailMessage={capencyCtx.emailMessage}
          phoneMessage={capencyCtx.phoneMessage}
        />
      </Show>
      <Show when={isSubmittedButNotValid(submission)}>
        <Errors issues={(submission.result as SubmissionFailed).issues} />
      </Show>
      {/* Form */}
      <Show
        when={
          !hasBeenSubmitted(submission) || isSubmittedButNotValid(submission)
        }
      >
        <form
          action={dispatch}
          method="post"
          style={{
            display: capencyIsDisplayed() ? "none" : "block",
          }}
        >
          <p class="introduction">
            Pour être recontacté par un conseiller immobilier par téléphone
            et/ou par email, merci de nous préciser les informations suivantes :
          </p>
          <SharedHiddenFields
            form_id={FORM_NAME}
            performCapencyCheck={performCapencyCheck()}
            defaultValues={{ ...props.defaultValues, ...props.hiddenFields }}
          />

          <Civility name="civility" value={formStore.values.civility} />
          <FirstName name="first_name" value={formStore.values.first_name} />
          <LastName name="last_name" value={formStore.values.last_name} />
          <Phone name="phone" value={formStore.values.phone} />
          <Email name="email" value={formStore.values.email} />
          <Location name="location" value={formStore.values.location} />

          <div class="clear" />

          <div class="plot-infos-container">
            <h3>Votre terrain :</h3>

            <PlotLocation
              name="plot_location"
              value={formStore.values.plot_location}
            />

            <Location
              name="plot_city_autocomplete"
              fieldNamePrefix="plot_"
              label="Ville du bien"
              value={formStore.values.plot_city_autocomplete}
            />

            <PlotPostalCode
              name="plot_postal_code"
              value={formStore.values.plot_postal_code}
            />

            <PlotSurface
              name="plot_surface"
              value={formStore.values.plot_surface}
            />

            <PlotComments
              name="plot_comments"
              value={formStore.values.plot_comments}
            />
          </div>

          <OptInCogedim name="optin_cogedim" showBrands />

          <button
            ref={btn}
            type="submit"
            class="btn form-submit"
            data-test="cta-submit"
            disabled={submission.pending}
            onClick={() => {
              setStart(true);
            }}
          >
            Soumettre
          </button>

          <Legals function="de rappel" />

          <Show when={submission.pending}>
            <p style={{ color: "blue" }} role="alert">
              <em>Envoi en cours…</em>
            </p>
          </Show>
        </form>
      </Show>
      {/* Confirmation */}
      <Show when={isSubmittedAndSuccessful(submission)}>
        <div class="confirmation">
          <i aria-hidden="true" class="cog-icon">
            <IconAccountBox />
          </i>
          <p>Nous avons bien reçu votre demande.</p>
          <p>
            Vous serez recontacté prochainement par nos équipes pour une
            première étude.
          </p>
          <p>
            Bien à vous, <br />
            L’équipe de développement COGEDIM
          </p>
        </div>
      </Show>
      <Show when={debug} keyed>
        <DebugDumper
          name={FORM_NAME}
          data={{ store: formStore, result: submission.result }}
        />
      </Show>
    </>
  );
}
