import type {
  InputTypeTextProps,
  SchemaOptions,
  ValidationFunc,
} from "~/components/Forms/Fields/Base/InputTypesProps";
import { InputTypeText } from "~/components/Forms/Fields/Base/InputTypeText";
import { createSignal, splitProps } from "solid-js";
import { maxLength, minLength, safeParse, string, pipe } from "valibot";
import ValidationErrors from "./Base/ValidationErrors";

const LABEL = "Code postal du bien";
const HELP = undefined;
const IS_REQUIRED_BY_DEFAULT = true;
const MIN_LENGTH = 3;
const MAX_LENGTH = 100;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function PlotPostalCodeSchema(options?: SchemaOptions) {
  return {
    plot_postal_code: pipe(
      string(),
      minLength(
        MIN_LENGTH,
        `Le ${LABEL.toLowerCase()} doit comporter au moins ${MIN_LENGTH} caractères`,
      ),
      maxLength(
        MAX_LENGTH,
        `Lettres, chiffres et signes de ponctuation autorisés. ${MAX_LENGTH} caractères max.`,
      ),
    ),
  };
}

export function PlotPostalCode(props: InputTypeTextProps) {
  const [local, others] = splitProps(props, ["label", "help", "required"]);
  const [validationErrors, setValidationErrors] = createSignal<string[]>([]);

  const validate: ValidationFunc = (input) => {
    const ret = safeParse(PlotPostalCodeSchema().plot_postal_code, input);
    ret.success
      ? setValidationErrors([])
      : setValidationErrors(ret.issues.map((issue) => issue.message));
  };

  return (
    <>
      <div class={`form-item-${props.name}`}>
        <InputTypeText
          label={local.label || LABEL}
          help={local.help || HELP}
          maxLength={MAX_LENGTH}
          validateFn={validate}
          aria-invalid={validationErrors().length > 0}
          aria-errormessage={`err-${props.name}`}
          required={
            local.required !== undefined
              ? local.required
              : IS_REQUIRED_BY_DEFAULT
          }
          {...others}
        />
        <ValidationErrors errors={validationErrors()} field={props.name} />
      </div>
    </>
  );
}
