import type {
  InputTypeTextProps,
  SchemaOptions,
  ValidationFunc,
} from "~/components/Forms/Fields/Base/InputTypesProps";
import { InputTypeText } from "~/components/Forms/Fields/Base/InputTypeText";
import { createSignal, splitProps } from "solid-js";
import { safeParse, custom } from "valibot";
import ValidationErrors from "./Base/ValidationErrors";

const LABEL = "Superficie du bien";
const HELP = undefined;
const IS_REQUIRED_BY_DEFAULT = true;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function PlotSurfaceSchema(options?: SchemaOptions) {
  return {
    plot_surface: custom((input) => {
      const i = parseInt(input);

      if (isNaN(i)) {
        return false;
      }

      return i > 499;
    }, "La superficie doit être supérieure à 500 m²"),
  };
}

export function PlotSurface(props: InputTypeTextProps) {
  const [local, others] = splitProps(props, ["label", "help", "required"]);
  const [validationErrors, setValidationErrors] = createSignal<string[]>([]);

  const validate: ValidationFunc = (input) => {
    const ret = safeParse(PlotSurfaceSchema().plot_surface, input);
    ret.success
      ? setValidationErrors([])
      : setValidationErrors(ret.issues.map((issue) => issue.message));
  };

  return (
    <>
      <div class={`form-item-${props.name}`}>
        <InputTypeText
          label={local.label || LABEL}
          help={local.help || HELP}
          validateFn={validate}
          aria-invalid={validationErrors().length > 0}
          aria-errormessage={`err-${props.name}`}
          required={
            local.required !== undefined
              ? local.required
              : IS_REQUIRED_BY_DEFAULT
          }
          {...others}
        />
        <ValidationErrors errors={validationErrors()} field={props.name} />
      </div>
    </>
  );
}
