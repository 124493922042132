import imgProxy from "~/utils/imgproxy";

export default function FirstScreen() {
  return (
    <>
      <section class="full-visual" data-test="first-screen">
        <picture class="visual">
          <source
            media="(min-width: 1367px)"
            srcset={imgProxy(
              "/images/plot/plot-visual.jpg",
              "size:1920:1080/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 1025px) and (max-width: 1366px)"
            srcset={imgProxy(
              "/images/plot/plot-visual.jpg",
              "size:1366:768/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 768px) and (max-width: 1024px)"
            srcset={imgProxy(
              "/images/plot/plot-visual.jpg",
              "size:1024:576/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 376px)"
            srcset={imgProxy(
              "/images/plot/plot-visual.jpg",
              "size:768:432/resizing_type:fill",
            )}
          />
          <source
            media="(max-width: 375px)"
            srcset={imgProxy(
              "/images/plot/plot-visual.jpg",
              "size:640:360/resizing_type:fill",
            )}
          />
          <img
            fetchpriority="high"
            src={imgProxy(
              "/images/plot/plot-visual.jpg",
              `size:640:360/resizing_type:fill`,
            )}
            alt=""
            height="360"
            width="375"
            loading="lazy"
          />
        </picture>
        <div class="gradient-corner" />
        <h1>Vous avez un terrain à vendre&nbsp;?</h1>
      </section>
    </>
  );
}
