export default function StrateEstimateSteps() {
  return (
    <>
      <section class="plot-strate estimate-steps">
        <div class="content-part">
          <h2>Votre estimation en 3 étapes</h2>
          <div class="items">
            <div class="item">
              <h3>
                <img src="/images/plot/plot-icon-05.png" alt="" />
                Étape 1
              </h3>
              <p>Remplissez le formulaire en ligne en quelques clics.</p>
            </div>
            <div class="item">
              <h3>
                <img src="/images/plot/plot-icon-06.png" alt="" />
                Étape 2
              </h3>
              <p>
                Nous vous contactons pour vous donner une première approche du
                potentiel de votre bien.
              </p>
            </div>
            <div class="item">
              <h3>
                <img src="/images/plot/plot-icon-07.png" alt="" />
                Étape 3
              </h3>
              <p>Recevez notre offre d'achat après une étude personnalisée.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
