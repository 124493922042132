import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import PlotPage from "~/components/Plot/PlotPage";

export default function VendreMonTerrain() {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Un terrain, une maison ou un immeuble à vendre ? Recevez une estimation d'achat de votre bien sous 72h, en direct promoteur et au meilleur prix.",
    },
  };

  return (
    <>
      <Title>Un terrain à vendre ? Contactez les experts de Cogedim</Title>
      <MetaTags tags={[metaDesc]} />
      <PlotPage />
    </>
  );
}
