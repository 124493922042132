import type {
  InputTypeTextProps,
  ValidationFunc,
} from "~/components/Forms/Fields/Base/InputTypesProps";
import { createSignal, splitProps } from "solid-js";
import {
  maxLength,
  minLength,
  optional,
  safeParse,
  string,
  pipe,
} from "valibot";
import ValidationErrors from "./Base/ValidationErrors";
import { TextArea } from "./Base/TextArea";
import IconMail from "~/img/icons/mail.svg";

const LABEL = "Commentaires";
const HELP = undefined;
const IS_REQUIRED_BY_DEFAULT = false;
const MIN_LENGTH = 10;
const MAX_LENGTH = 1000;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function PlotCommentsSchema() {
  const schema = pipe(
    string(),
    minLength(
      MIN_LENGTH,
      `Le champs ${LABEL.toLowerCase()} doit comporter au moins ${MIN_LENGTH} caractères`,
    ),
    maxLength(
      MAX_LENGTH,
      `Lettres, chiffres et signes de ponctuation autorisés. ${MAX_LENGTH} caractères max.`,
    ),
  );

  return {
    plot_comments: optional(schema),
  };
}

export function PlotComments(props: InputTypeTextProps) {
  const [local, others] = splitProps(props, ["label", "help", "required"]);
  const [validationErrors, setValidationErrors] = createSignal<string[]>([]);

  const validate: ValidationFunc = (input) => {
    const ret = safeParse(PlotCommentsSchema().plot_comments, input);
    ret.success
      ? setValidationErrors([])
      : setValidationErrors(ret.issues.map((issue) => issue.message));
  };

  return (
    <>
      <div class={`form-item-${props.name}`}>
        <TextArea
          label={local.label || LABEL}
          help={local.help || HELP}
          maxLength={MAX_LENGTH}
          validateFn={validate}
          aria-invalid={validationErrors().length > 0}
          aria-errormessage={`err-${props.name}`}
          required={
            local.required !== undefined
              ? local.required
              : IS_REQUIRED_BY_DEFAULT
          }
          prependIcon={<IconMail />}
          {...others}
        />
        <ValidationErrors
          errors={validationErrors()}
          field={props.name}
          withPrependIcon
        />
      </div>
    </>
  );
}
