export default function StrateReceiveEstimate() {
  return (
    <>
      <section class="plot-strate receive-estimate">
        <div class="content-part">
          <h2>
            Recevez une estimation d'achat de votre bien rapide, en direct et au
            meilleur prix
          </h2>
          <p class="catchline">
            Une vente directe promoteur bien plus avantageuse qu'avec un
            particulier.
          </p>
          <div class="items">
            <p class="item">
              <img src="/images/plot/plot-icon-01.png" alt="" />
              Terrain nu en secteur
              <br />
              constructible
            </p>
            <p class="item">
              <img src="/images/plot/plot-icon-02.png" alt="" />
              Maison individuelle
              <br />
              avec terrain
            </p>
            <p class="item">
              <img src="/images/plot/plot-icon-03.png" alt="" />
              Immeuble en
              <br />
              pleine propriété
            </p>
            <p class="item">
              <img src="/images/plot/plot-icon-04.png" alt="" />
              Bâtiment d'activités
              <br />
              <span>
                (entreprise, commerce,
                <br />
                garage)
              </span>
            </p>
          </div>
          <a href="#plot-form" class="btn btn-scroll">
            J’estime mon bien
          </a>
        </div>
      </section>
    </>
  );
}
