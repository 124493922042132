import imgProxy from "~/utils/imgproxy";
import { urlRs } from "~/utils/url";

export default function StrateKeyFigures() {
  return (
    <>
      <section class="plot-strate plot-key-figures">
        <div class="content-part">
          <h2>Chiffres clés</h2>
          <div class="items">
            <div class="item row-1">
              <picture class="visual">
                <img
                  fetchpriority="high"
                  src={imgProxy(
                    "/images/plot/plot-visual-02b.jpg",
                    `size:400:450/resizing_type:fill`,
                  )}
                  alt=""
                  height="422"
                  width="375"
                  loading="lazy"
                />
              </picture>
              <div class="text-part">
                <h3>2ème</h3>
                <p>Groupe immobilier de France</p>
                <h3>Depuis 1963</h3>
                <p>
                  Cogedim est un solide partenaire des villes et des
                  collectivités
                </p>
                <h3>+ de 120 000</h3>
                <p>Logements réalisés par Cogedim depuis sa création</p>
                <h3>+ de 100</h3>
                <p>Projets de promotion en moyenne par an</p>
              </div>
            </div>

            <div class="item row-2">
              <picture class="visual">
                <img
                  fetchpriority="high"
                  src={imgProxy(
                    "/images/plot/plot-visual-03.jpg",
                    `size:400:450`,
                  )}
                  alt=""
                  height="422"
                  width="375"
                  loading="lazy"
                />
              </picture>
              <div class="text-part">
                <h3>Une démarche éco-responsable</h3>
                <p>
                  COGEDIM s’engage à construire des biens qui font du
                  bien&nbsp;; des logements qui ont un impact positif sur la
                  santé et sur l’environnement.
                </p>
                <h3>Nos engagements pour des biens qui font du bien</h3>
                <p>
                  Retrouvez nos engagements en matière de santé, de bien-être et
                  d'éco-responsabilité.
                </p>
                <p>
                  <a
                    href={urlRs(
                      "enga",
                      "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/",
                    )}
                    class="btn"
                  >
                    Découvrir nos engagements
                  </a>
                </p>
              </div>
            </div>
          </div>
          <a href="#plot-form" class="btn btn-scroll">
            J’estime mon bien
          </a>
        </div>
      </section>
    </>
  );
}
